import React, { useState, useEffect } from 'react'
import './index.less'
import { Steps, Button, Layout } from 'antd'
import Foot from '../../components/Foot'
import StepTemp from './StepTemp'
import EndTemp from './EndTemp'

import { getTopicGroupsRequest } from '../../service/api'
const { Content } = Layout
const { Step } = Steps
const topicIdTag = 'ff80c7f36ee9411f8f507dfacf3e24d9'
// 课题
const Subject = props => {
  const topicId =
    (props.location.state && props.location.state.topicId) || topicIdTag
  const [current, setCurrent] = useState(0)
  const [stepTitles, setStepTitles] = useState([])
  const [canNext, setCanNext] = useState(true)
  const [refresh, setRefresh] = useState(false)
  const [lists, setLists] = useState([])
  //console.log(current)

  // const getTopicGroupList = useCallback(async () => {
  //   const res = await getTopicGroupsRequest({
  //     topicId: topicId,
  //   })
  //   const resData = res.data
  //   if (resData.code === 200) {
  //     const list = resData.data
  //     list.push({ id: '12', name: '完成', sort: '11' })
  //     setStepTitles(list)
  //     setRefresh(true)
  //   }
  // }, [])
  useEffect(() => {
    refresh && setTimeout(() => setRefresh(false))
  }, [refresh])
  useEffect(() => {
    const getTopicGroupList = async () => {
      const res = await getTopicGroupsRequest({
        topicId: topicId,
      })
      const resData = res.data
      if (resData.code === 200) {
        const lists = resData.data
        let copyList = [...lists]
        // ui 增加 完成 步骤
        copyList.push({ id: 'end', name: '完成', sort: `${lists.length + 1}` })
        setLists(copyList)
        setStepTitles(copyList)
        setRefresh(true)
      }
    }
    getTopicGroupList()
  }, [topicId])

  const next = () => {
    // 当前步骤
    setCurrent(current + 1)
    //下一步按钮显示
    setCanNext(true)
  }

  const prev = () => {
    setCurrent(current - 1)
  }
  // const onChangeStep = current => {
  //   // 测试工具 线上不启用
  //   console.log('onChange:', current)
  //   setCurrent(current)
  //   checkStep(current, lists)
  // }
  const checkStep = (current, lists) => {
    const Len = lists.length
    for (let i = 0; i < Len; i++) {
      if (current === i) {
        if (i === Len - 1) {
          return <EndTemp groupId={lists[current].id} topicId={topicId} />
        }
        return (
          <StepTemp
            topicId={topicId}
            key={current}
            setCanNext={setCanNext}
            groupId={lists[current].id}
          />
        )
      }
    }
  }

  return (
    <Layout direction="vertical" className="subject-content-wrap">
      <Content className="subject-content">
        {/* 步骤 */}
        <div>
          <h2 className="step-title">申报书填写步骤</h2>
          <Steps
            current={current}
            // onChange={onChangeStep}
            direction="horizontal"
            className="step-wrap"
          >
            {stepTitles &&
              stepTitles.map(item => (
                <Step
                  key={item.name}
                  title={item.name}
                  className="step-inline"
                />
              ))}
          </Steps>
        </div>
        {/* 步骤 */}
        {lists && lists.length > 0 && checkStep(current, lists)}
        {/* 下一步 */}
        <p className="step-next-wrap">
          {current > 0 && (
            <Button className="step-button" onClick={() => prev()}>
              上一步
            </Button>
          )}
          {current < stepTitles.length - 1 && (
            <Button
              className="step-button"
              type="primary"
              onClick={() => next()}
              disabled={canNext}
            >
              下一步
            </Button>
          )}
        </p>
        <Foot />
      </Content>
    </Layout>
  )
}
export default Subject
