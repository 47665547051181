import moment from 'moment'
import { isArray } from '../../utils'

//双日期合并字符串，多文件上传格式化
export const objToArr = obj => {
  //没有考虑
  // 分类几种情况 表单1、只用拖动上传了一个附件 obj  {dragger: Array(1)} 然后保存，启动这个函数
  //
  var arr = []
  for (var i in obj) {
    var newObj = {}
    newObj.topicGroupFieldId = i
    //表单2：有日期，还有附件
    if (isArray(obj[i])) {
      if (obj[i][0]._isAMomentObject) {
        //1.是数组、双日期
        const rangeTime =
          obj[i][0].format('YYYY-MM-DD') + '~' + obj[i][1].format('YYYY-MM-DD')
        newObj.topicGroupFieldWrite = rangeTime
      } else {
        // 2、上传列表
        const fileList = obj[i]
        const resourceIds = []
        fileList.forEach(item => {
          resourceIds.push(item.response.data.data)
        })
        newObj.topicGroupFieldWrite = JSON.stringify(resourceIds)
      }
    } else if (moment.isMoment(obj[i])) {
      // 3.单时间
      newObj.topicGroupFieldWrite = obj[i].format('YYYY-MM-DD')
    } else if (
      typeof obj[i] !== 'undefined' &&
      obj[i].hasOwnProperty('fileList')
    ) {
      // 有数据列表 fileList 是否使用？待确认？
      const fileList = obj[i].fileList
      const resourceIds = []
      fileList.forEach(item => {
        resourceIds.push(item.response.data.data)
      })
      newObj.topicGroupFieldWrite = JSON.stringify(resourceIds)
    } else if (typeof obj[i] == 'undefined') {
      newObj.topicGroupFieldWrite = ''
    } else {
      newObj.topicGroupFieldWrite = obj[i]
    }
    arr.push(newObj)
  }
  return arr
}
export const download = (response, time) => {
  // 下载文件
  let blob = new Blob([response.data], {
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document这里表示doc类型',
  })
  let contentDisposition = response.headers['content-disposition'] //从response的headers中获取filename, 后端response.setHeader("Content-disposition", "attachment; filename=xxxx.docx") 设置的文件名;
  let fileName = '模板下载'
  if (contentDisposition) {
    fileName = window.decodeURI(
      response.headers['content-disposition'].split('=')[1],
      'UTF-8'
    )
  }
  let downloadElement = document.createElement('a')
  let href = window.URL.createObjectURL(blob) //创建下载的链接
  downloadElement.style.display = 'none'
  downloadElement.href = href
  downloadElement.download = fileName
  document.body.appendChild(downloadElement)
  downloadElement.click() //点击下载
  document.body.removeChild(downloadElement) //下载完成移除元素
  window.URL.revokeObjectURL(href) //释放掉blob对象
}
