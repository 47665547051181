import React, { useState, useEffect } from 'react'
import './index.less'
import Foot from '../../components/Foot'
import { Tabs, Button, Table, Modal, message, Form, Input } from 'antd'
import {
  applyInfoRequest,
  deleteDynamicRequest,
  modifyDynamicRequest,
  userInfoRequest,
  updateUserRequest,
} from '../../service/api'
import { PAGE_SIZE } from '../../utils/Constants'
const { confirm } = Modal
const { TabPane } = Tabs

const Profile = props => {
  const [details, setDetails] = useState([])
  const [Loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const [currentPageNum, setCurrentPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const [total, setTotal] = useState(0)
  // 课题详情页
  const [visibleDetail, setVisibleDetail] = useState(false)
  const [detail, setDetail] = useState({})

  const [userInfo, setUserInfo] = useState({})

  /*
  获取申报列表
   */
  const getApplyInfoList = async (currentPageNum, pageSize) => {
    setLoading(true)
    const res = await applyInfoRequest({
      page: currentPageNum || 1,
      rows: pageSize,
    })
    const resData = res.data
    if (resData.code === 200) {
      setLoading(false)
      setDetails(resData.data.list)
      setTotal(resData.data.totalRecords)
    }
  }

  useEffect(() => {
    getApplyInfoList(currentPageNum, pageSize)
  }, [currentPageNum, pageSize])
  //个人信息

  const getUserInfoList = async () => {
    setLoading(true)
    const res = await userInfoRequest()
    const resData = res.data
    if (resData.code === 200) {
      setLoading(false)
      setUserInfo(resData.data)
    }
  }
  useEffect(() => {
    // 个人信息
    getUserInfoList()
  }, [])

  // todo  删除
  /*
  显示删除功能 
   */
  const deleteModal = detail => {
    confirm({
      title: `是否确认删除'  ${detail.applyTopicName} '?`,
      onOk() {
        deleteDetail(detail.id)
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }
  const deleteDetail = async id => {
    const formData = new FormData()
    formData.append('applyId', id)
    const res = await deleteDynamicRequest(formData)
    if (res.data.code === 200) {
      message.success('删除成功')
      getApplyInfoList(currentPageNum, pageSize)
    } else {
      message.error(res.data.message)
    }
  }
  /*
  显示撤销功能 
   */
  const modifyModal = detail => {
    confirm({
      title: `是否确认撤销'  ${detail.applyTopicName} '?`,
      onOk() {
        modifyDetail(detail.id)
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }
  const modifyDetail = async id => {
    const formData = new FormData()
    formData.append('applyId', id)
    const res = await modifyDynamicRequest(formData)
    if (res.data.code === 200) {
      message.success('撤销成功')
      getApplyInfoList(currentPageNum, pageSize)
    } else {
      message.error(res.data.message)
    }
  }
  // 翻页
  const currentPageChange = current => {
    setCurrentPageNum(current)
  }
  // 条数改变
  const pageSizeChange = (currentPageNum, pageSize) => {
    //设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
    setPageSize(pageSize)
  }
  // 列表
  const columns = [
    {
      title: '提交时间',
      dataIndex: 'applyEndTime',
      width: 220,
    },
    {
      title: '申报课题名称',
      dataIndex: 'applyTopicName',
    },
    // {
    //   title: '申报进度',
    //   dataIndex: 'applyStatusText',
    // },
    {
      title: '操作',
      align: 'center',
      width: 250,
      render: record => (
        <>
          <Button type="link" onClick={() => showModal(record)}>
            详情
          </Button>
          <Button type="link" onClick={() => modifyModal(record)}>
            撤回
          </Button>
          <Button type="link" onClick={() => deleteModal(record)}>
            删除
          </Button>
        </>
      ),
    },
  ]
  const onFinishProfiles = async values => {
    const formData = new FormData()
    formData.append('userId', userInfo.id)
    formData.append('nickName', values.nickName)
    const res = await updateUserRequest(formData)
    //console.log(res.data)
    if (res.data.code === 200) {
      message.success('修改信息成功')
      getUserInfoList()
    } else {
      message.error(res.data.message)
    }
  }
  const handleCancel = () => {
    setVisibleDetail(false)
  }
  const showModal = detail => {
    setVisibleDetail(true)
    setDetail(detail)
  }
  return (
    <div className="profile">
      <div className="profile-head-wrap">
        <h2 className="profile-head">个人中心</h2>
      </div>
      <div className="profile-tabs">
        <Tabs tabPosition="left" type="card">
          <TabPane tab="申报动态" key="1">
            <Table
              loading={Loading}
              dataSource={details}
              columns={columns}
              rowKey="id"
              pagination={{
                showSizeChanger: true,
                total: total, //数据的总的条数
                showTotal: detailTotal => `总共 ${total} 条记录`,
                pageSize: pageSize,
                onChange: currentPageNum => currentPageChange(currentPageNum), //翻页
                onShowSizeChange: (currentPageNum, pageSize) =>
                  pageSizeChange(currentPageNum, pageSize),
              }}
            ></Table>
          </TabPane>
          <TabPane tab="个人设置" key="2">
            <Form
              name="basic"
              labelCol={{
                span: 4,
              }}
              wrapperCol={{
                span: 8,
              }}
              initialValues={{
                nickName: userInfo.nickName,
                mobile: userInfo.mobile,
              }}
              onFinish={onFinishProfiles}
              autoComplete="off"
              form={form}
            >
              <Form.Item
                label="姓名"
                name="nickName"
                rules={[
                  {
                    required: true,
                    message: '请输入昵称!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item label="手机号" name="mobile">
                <Input disabled />
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 4,
                  span: 8,
                }}
              >
                <Button type="primary" htmlType="submit">
                  保存
                </Button>
              </Form.Item>
            </Form>
          </TabPane>
        </Tabs>
        <Modal
          title="详情"
          visible={visibleDetail}
          onCancel={handleCancel}
          footer={null}
          closable
        >
          <p>
            <span className="profileLabel">申报单位:</span>{' '}
            {detail.applyOrgName}
          </p>
          <p>
            <span className="profileLabel">申报名称: </span>
            {detail.applyTopicName}
          </p>
          <p>
            <span className="profileLabel">申报进度: </span>
            {detail.applyStatusText}
          </p>
          <p>
            <span className="profileLabel">审核状态:</span>{' '}
            {detail.examineStatusText}
          </p>
          <p>
            <span className="profileLabel">课题名称: </span>
            {detail.topicName}
          </p>
        </Modal>
      </div>
      <Foot />
    </div>
  )
}
export default Profile
