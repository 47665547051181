/*
 * @Author: your name
 * @Date: 2021-06-03 22:19:09
 * @LastEditTime: 2021-07-06 17:57:22
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /wf-knowledge-manager-static/src/service/auth.js
 */
import axios from 'axios'
import qs from 'qs'
const baseUrl = process.env.REACT_APP_API_URL
const Token = '__wf__auth_token__'
const MENU = '__wf__auth__menu__'
const USERMENU = '__wf__auth__usermenu__'
const UUID = '__wf__auth__uuid__'
// uuid
export function getUUID() {
  return localStorage.getItem(UUID)
}
export function setUUID(uuid) {
  localStorage.setItem(UUID, uuid)
}

// token
export function getToken() {
  return localStorage.getItem(Token)
}
export function setToken(token) {
  localStorage.setItem(Token, token)
}

//菜单
export function getMenu() {
  return JSON.parse(localStorage.getItem(MENU))
}
export function setMenu(menu) {
  localStorage.setItem(MENU, JSON.stringify(menu) || '')
}
//用户菜单
export function getUserMenu() {
  return JSON.parse(localStorage.getItem(USERMENU))
}
export function setUserMenu(usermenu) {
  localStorage.setItem(USERMENU, JSON.stringify(usermenu) || '')
}
// 是否登录
export function isLogined() {
  if (localStorage.getItem(Token)) {
    return true
  }
  return false
}
export function clearToken() {
  // 清除token
  localStorage.removeItem(Token)
  // 清除菜单
  localStorage.removeItem(MENU)
  // 清除菜单
  localStorage.removeItem(USERMENU)
}

//登录
const loginRequest = data => {
  return axios({
    url: `${baseUrl}/v1/auth/sms`,
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(data),
  })
}
export const login = (values, redirect) => {
  if (!getUUID()) {
    setUUID(new Date().getTime())
  }
  return new Promise((resolve, reject) => {
    loginRequest({
      mobile: values.contactPhone,
      code: values.captcha,
      ui: getUUID(),
    })
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
  // loginRequest({
  //   mobile: values.contactPhone,
  //   code: values.captcha,
  //   ui: getUUID(),
  // }).then(resp => {
  //   const data = resp.data
  //   if (data.code === 200) {
  //     const token = resp.data.data
  //     setToken(token)

  //   } else {
  //     data.message && message.warning(data.message)
  //   }
  // })
}
export const logout = async () => {
  clearToken()
}
