import React from 'react'
import { Layout } from 'antd'
import './index.less'
const { Footer } = Layout
const Foot = () => {
  return (
    <Footer className="foot">
      <p>关于我们 | 友情链接 | 联系我们 京ICP备20013770号-3 © 2021</p>
      <p>威凤教育-校企合作平台 Email：wephoenix@fengedu.com</p>
      <p>威凤国际教育科技（北京）有限公司</p>
    </Footer>
  )
}
export default Foot
