/*
 * @Author: your name
 * @Date: 2021-06-26 11:12:57
 * @LastEditTime: 2021-07-06 14:54:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /wf-knowledge-manager-static/src/views/Notice/notice-detail.js
 */
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Layout } from 'antd'

import './index.less'
import { detailNoticeRequest, downTopicFieldsRequest } from '../../service/api'
import { download } from '../../utils'
import 'braft-editor/dist/index.css'
import Foot from '../../components/Foot'
const { Content } = Layout
const Detail = props => {
  const [notice, setNotice] = useState([])
  const {
    match: {
      params: { id },
    },
  } = props

  useEffect(() => {
    getDetailList(id)
  }, [id])

  //获取发布列表
  const getDetailList = async id => {
    const res = await detailNoticeRequest(id)
    const resData = res.data
    if (resData.code === 200) {
      setNotice(resData.data)
    }
  }
  const handleDownload = async resouceId => {
    const res = await downTopicFieldsRequest(resouceId)
    try {
      download(res)
    } catch (error) {
      console.log(error)
    }
  }
  return (
    // <Card title="课题详情">
    //   <div>
    //     <div>
    //       <span className="notice-left">课题名称:</span>
    //       <span>{notice.name}</span>
    //     </div>
    //     <div>
    //       <span className="notice-left">开始时间:</span>
    //       <span>{notice.startTime}</span>
    //     </div>
    //     <div>
    //       <span className="notice-left">结束时间:</span>
    //       <span>{notice.endTime}</span>
    //     </div>
    //     <div>
    //       <span className="notice-left">课题声明:</span>
    //       <div dangerouslySetInnerHTML={{ __html: notice.content }}></div>
    //     </div>
    //   </div>
    // </Card>
    <div className="detail">
      <div className="article-title">
        <h2>{notice.title}</h2>
      </div>
      <Content className="article-content-wrap">
        <div className="article-time">
          {moment(notice.updateTime).format('YYYY-MM-DD')}
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: notice.content }}
          className="article-content"
        ></div>
        {notice.isButton === 1 ? (
          <div className="article-bottom-wrap">
            <a className="article-bottom-button" href={notice.buttonUrl}>
              {notice.buttonName}
            </a>
          </div>
        ) : null}
        {notice.resources && notice.resources.length > 0
          ? notice.resources.map((item, index) => {
              return (
                <div
                  key={item.id}
                  onClick={() => {
                    handleDownload(item.id)
                  }}
                  style={{ cursor: 'pointer', color: '#A8081A' }}
                >
                  附件{index + 1}： {item.sourceName}
                </div>
              )
            })
          : null}
        {/* <div className="page-view-count">浏览量：100</div> 
        <Divider dashed="true" />
     <h2>上一篇：这里是标题</h2>
        <h2>下一篇：这里是标题</h2> */}
      </Content>
      <Foot />
    </div>
  )
}
export default Detail
