import React, { useState } from 'react'
import { Form, Input, Button, Col, Row, message, Divider } from 'antd'
import { CheckCircleTwoTone } from '@ant-design/icons'
import { smsRequest } from '../../service/captcha'
import { subjectFinishRequest } from '../../service/api'
import './index.less'
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
}
export default function Step12(props) {
  const { topicId } = props
  const [isDisabled, setIsDisabled] = useState(false)
  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()

  const onSendPhoneCaptcha = async e => {
    const { contactPhone } = await form.validateFields(['contactPhone'])
    if (contactPhone) {
      smsRequest({
        mobile: contactPhone,
        type: 'TOPIC_APPLY_CODE',
      }).then(resp => {
        // console.log(resp)
        // console.log(resp.status)
        if (resp.status === 200) {
          let timeNum = 60
          /*当点击获取验证码后设置60秒计时不可点击*/
          const timeTicket = setInterval(function () {
            if (timeNum > 1) {
              timeNum--
              /*设置按钮不可点击*/
              setIsDisabled(true)
              e.target.innerText = timeNum + '秒后可点击'
            } else if (timeNum === 1) {
              e.target.innerText = '获取验证码'
              setIsDisabled(false)
              timeNum = 60
              clearInterval(timeTicket)
            }
          }, 1000)
          if (resp.data.code === 200) {
            message.success('验证码发送成功')
          } else {
            message.error(resp.data.message)
          }
        } else {
          message.error(resp.data.message)
        }
      })
    }
  }
  const onFinish = values => {
    console.log(values)
    subjectFinishRequest(topicId, {
      mobile: values.contactPhone,
      code: values.captcha,
      applyTopicName: values.applyTopicName,
      applyUser: values.applyUser,
      applyOrgName: values.applyOrgName,
    }).then(resp => {
      const data = resp.data
      if (data.code === 200) {
        setVisible(true)
      } else {
        data.message && message.warning(data.message)
      }
    })
  }
  return !visible ? (
    <div className="step-finished">
      <h2>短信通知</h2>
      <p>提交您的课题申报，将以短息的形式通知您课题申报情况。</p>
      <div className="login-wrap">
        <Form
          {...layout}
          name="normal_login"
          className="login-form"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            label="课题名称"
            name="applyTopicName"
            rules={[
              {
                required: true,
                message: `请输入课题名称`,
              },
            ]}
          >
            <Input placeholder="请输入课题名称" />
          </Form.Item>
          <Form.Item
            label="责任人"
            name="applyUser"
            rules={[
              {
                required: true,
                message: `请输入责任人`,
              },
            ]}
          >
            <Input placeholder="请输入责任人" />
          </Form.Item>
          <Form.Item
            label="单位名称"
            name="applyOrgName"
            rules={[
              {
                required: true,
                message: `请输入单位名称`,
              },
            ]}
          >
            <Input placeholder="请输入单位名称" />
          </Form.Item>
          <Form.Item
            label="责任人电话"
            name="contactPhone"
            rules={[
              {
                pattern: /^1[3|4|5|7|8][0-9]\d{8}$/,
                required: true,
                message: '请输入正确的手机号!',
              },
            ]}
          >
            <Input addonBefore="+86" placeholder="11位手机号" />
          </Form.Item>
          <Form.Item label="验证码">
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  name="captcha"
                  rules={[
                    {
                      required: true,
                      message: '请输入手机验证码',
                    },
                  ]}
                >
                  <Input placeholder="输入验证码" autoComplete="off" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Button onClick={onSendPhoneCaptcha} disabled={isDisabled}>
                  获取验证码
                </Button>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              提交
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  ) : (
    <div className="step-finished">
      <div className="success-icon">
        <CheckCircleTwoTone
          twoToneColor="#52c41a"
          style={{ fontSize: '62px', marginBottom: 20 }}
        />
      </div>
      <h2 className="success-title">提交成功</h2>
      <p className="summary">
        您的课题申报情况将以短信的形式通知您，请及时查看手机信息
      </p>
      <Divider style={{ width: '100%' }} />
    </div>
  )
}
