import React, { useEffect, useState } from 'react'
import { Layout, Divider, Pagination } from 'antd'
import moment from 'moment'
import Foot from '../../components/Foot'
import { Link } from 'react-router-dom'
import './index.less'
import { noticeListRequest } from '../../service/api'
import Banner from '../../components/Banner'
import { PAGE_SIZE } from '../../utils/Constants'

const { Content } = Layout

const Notice = () => {
  const [notices, setNotices] = useState([])

  const [currentPageNum, setCurrentPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const [total, setTotal] = useState(0)

  useEffect(() => {
    getNoticesList(currentPageNum, pageSize)
  }, [currentPageNum, pageSize])
  // 翻页
  const currentPageChange = current => {
    setCurrentPageNum(current)
  }
  // 条数改变
  const pageSizeChange = (currentPageNum, pageSize) => {
    //设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
    setPageSize(pageSize)
  }

  /*
  获取新闻列表
   */
  const getNoticesList = async (currentPageNum, pageSize) => {
    const res = await noticeListRequest({
      page: currentPageNum || 1,
      rows: pageSize,
    })
    const resData = res.data
    if (resData.code === 200) {
      setNotices(resData.data.list)
      setTotal(resData.data.totalRecords)
    } else {
    }
  }

  return (
    <div className="notice">
      <Layout>
        <Content>
          <Banner />
          <div className="notice-head-wrap">
            <h2 className="notice-head">
              <span></span>通知公告
            </h2>
          </div>
        </Content>
        <Content className="notice-list-wrap">
          <Divider dashed />
          {notices &&
            notices.map(notice => {
              return (
                <Link to={`/detail/${notice.id}`} key={notice.id}>
                  <div className="notice-list-item">
                    <div className="new-title">{notice.title}</div>
                    <div className="new-time">
                      {moment(notice.updateTime).format('YYYY-MM-DD')}
                    </div>
                  </div>
                  <Divider className="divier" />
                </Link>
              )
            })
          }
          <Pagination
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '25px',
            }}
            total={total}
            showSizeChanger={true}
            showTotal={detailTotal => `总共 ${total} 条记录`}
            pageSize={pageSize}
            onChange={currentPageNum => currentPageChange(currentPageNum)} //翻页
            onShowSizeChange={(currentPageNum, pageSize) =>
              pageSizeChange(currentPageNum, pageSize)
            }
          />
        </Content>
      </Layout>
      <Foot />
    </div>
  )
}
export default Notice
