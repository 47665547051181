/*
 * @Author: your name
 * @Date: 2021-06-03 22:19:09
 * @LastEditTime: 2021-07-06 16:13:18
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /wf-knowledge-manager-static/src/service/auth.js
 */
import axios from 'axios'
import qs from 'qs'
const baseUrl = process.env.REACT_APP_API_URL

// 验证码
export const smsRequest = data => {
  return axios({
    url: `${baseUrl}/v1/sms`,
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(data),
  })
}
