import React, { useState } from 'react'
import { Layout, Menu, Dropdown, Avatar } from 'antd'
import { NavLink, useLocation } from 'react-router-dom'
import { withRouter } from 'react-router'
import { UserOutlined } from '@ant-design/icons'
import { useAuth } from '../../context/auth-context'
import { currentDomain } from '../../utils/Constants'
import './index.less'
const { Header } = Layout
const Head = props => {
  const { user, logout } = useAuth()
  const { pathname } = useLocation()
  let currentName = pathname
  if (pathname === '/detail') {
    currentName = '/notice'
  }

  const [current, setCurrent] = useState(currentName)

  const handleClick = e => {
    setCurrent(e.key)
  }
  const popMenu = (
    <Menu
      onClick={p => {
        if (p.key === 'logout') {
          logout()
          props.history.push('/login')
        }
        if (p.key === 'system') {
          // window.location.href = 'http://172.24.55.28:9082/admin/auth/dashboard'
          window.location.href = `${currentDomain}/admin/auth/dashboard`
        }
        if (p.key === 'audit') {
          // window.location.href = 'http://172.24.55.28:9082/admin/auth/dashboard'
          window.location.href = `${currentDomain}/profile`
        }
      }}
    >
      <Menu.Item key="username"> {user?.USER?.userName}</Menu.Item>
      {user?.USER?.roles.length > 1 ? (
        <Menu.Item key="system">后台系统</Menu.Item>
      ) : null}
      <Menu.Item key="audit">个人中心</Menu.Item>
      <Menu.Item key="logout">退出</Menu.Item>
    </Menu>
  )
  return (
    <Header className="head-wrap">
      <div className="home-nav">
        <div className="home-left">
          <div className="logo">
            <NavLink to="/" replace>
              课题申报
            </NavLink>
          </div>
          <Menu
            theme="dark"
            onClick={handleClick}
            mode="horizontal"
            selectedKeys={[current]}
            defaultOpenKeys={['/']}
            style={{ color: '#fff' }}
          >
            <Menu.Item key="/">
              <NavLink to="/" replace>
                首页
              </NavLink>
            </Menu.Item>
            <Menu.Item key="/subject">
              <NavLink to="/subject" replace>
                课题申报
              </NavLink>
            </Menu.Item>
            <Menu.Item key="/notice">
              <NavLink to="/notice" replace>
                通知公告
              </NavLink>
            </Menu.Item>
          </Menu>
        </div>
        <div className="home-right">
          {user ? (
            <Dropdown overlay={popMenu} className="header-avatar" arrow>
              <div>
                <Avatar
                  style={{ backgroundColor: '#797882', color: '#090721' }}
                  icon={<UserOutlined />}
                />
              </div>
            </Dropdown>
          ) : (
            <NavLink to="/login" replace>
              登录
            </NavLink>
          )}
        </div>
        {/* <Modal
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          style={{ borderRadius: '24px', padding: '20px' }}
        >
          <Login />
        </Modal> */}
      </div>
    </Header>
  )
}
export default withRouter(Head)
