import { useEffect, useState } from 'react'
/*
  用户信息 token解码
*/
export const base64User = token => {
  const newToken = token.replace(/_/g, '/')
  const newToken2 = newToken.replace(/-/g, '+')
  const tokendata = newToken2.split(' ')[1].split('.')[1]
  const user = decodeURIComponent(escape(window.atob(tokendata)))
  return JSON.parse(user)
}
/*
  格式化日期
*/
export function formateDate(time) {
  if (!time) return ''
  let date = new Date(time)
  return (
    date.getFullYear() +
    '-' +
    (date.getMonth() + 1) +
    '-' +
    date.getDate() +
    ' ' +
    date.getHours() +
    ':' +
    date.getMinutes() +
    ':' +
    date.getSeconds()
  )
}
export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    // 每次在value变化以后，设置一个定时器
    const timeout = setTimeout(() => setDebouncedValue(value), delay)
    // 每次在上一个useEffect处理完以后再运行
    return () => clearTimeout(timeout)
  }, [value, delay])

  return debouncedValue
}
//过滤对象
export const isFalsy = value => (value === 0 ? false : !value) //  转成boolean 值 排除value 为0情况

export const cleanObject = object => {
  //Object.assign({},object)// 浅拷贝
  const result = { ...object }
  Object.keys(object).forEach(key => {
    const value = object[key]
    // 0 也被delete 但是我们value有0值
    if (isFalsy(value)) {
      // null undefined
      delete result[key]
    }
  })
  return result
}
//子组件为空过滤
export const treeDone = data => {
  data.forEach(item => {
    if (item.subitems && item.subitems.length > 0) {
      item = treeDone(item.subitems)
    } else {
      delete item.subitems
    }

    return item
  })

  return data
}

export const isArray = obj => {
  return Object.prototype.toString.call(obj) === '[object Array]'
}
export const useMount = (callback: () => void) => {
  useEffect(() => {
    callback()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
// 下载文件
export const download = (response, time) => {
  let blob = new Blob([response.data], {
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document这里表示doc类型',
  })
  let contentDisposition = response.headers['content-disposition'] //从response的headers中获取filename, 后端response.setHeader("Content-disposition", "attachment; filename=xxxx.docx") 设置的文件名;
  let fileName = '下载'
  if (contentDisposition) {
    fileName = window.decodeURI(
      response.headers['content-disposition'].split('=')[1],
      'UTF-8'
    )
  }
  let downloadElement = document.createElement('a')
  let href = window.URL.createObjectURL(blob) //创建下载的链接
  downloadElement.style.display = 'none'
  downloadElement.href = href
  downloadElement.download = fileName
  document.body.appendChild(downloadElement)
  downloadElement.click() //点击下载
  document.body.removeChild(downloadElement) //下载完成移除元素
  window.URL.revokeObjectURL(href) //释放掉blob对象
}
