/*
 * @Author: your name
 * @Date: 2021-07-06 15:57:51
 * @LastEditTime: 2021-07-06 18:33:59
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /wf-knowledge-manager-static/src/context/auth-context.js
 */
import React, { useState, useEffect } from 'react'
import * as auth from '../auth-provider'
import { message } from 'antd'
import { base64User, treeDone } from '../utils'
import { getAllMenuListRequest, getUserMenuListRequest } from '../service/api'
const AuthContext = React.createContext(undefined)
AuthContext.displayName = 'AuthContext'

export const AuthProvider = ({ children, props }) => {
  const [user, setUser] = useState(null)
  useEffect(() => {
    const token = auth.getToken()
    const user = token && base64User(token)
    setUser(user)
  }, [setUser])

  //所有菜单menus 格式化子菜单为空的情况
  const formatData = data => {
    // 格式化子组件为空的情况
    const newData = JSON.parse(JSON.stringify(data))
    return newData && treeDone(newData)
  }
  //获取菜单
  const getMenuList = async () => {
    return await getAllMenuListRequest()
  }
  // 登录后获取用户菜单
  const getUserMenuList = async () => {
    return await getUserMenuListRequest()
  }
  const login = async form =>
    auth
      .login(form)
      .then(reponse => {
        console.log(reponse)
        const data = reponse.data
        if (data.code === 200) {
          const token = data.data
          auth.setToken(token)
          const user = token && base64User(token)
          setUser(user)
          // 登录后获取所有菜单
          if (token) {
            console.log('enter')
            Promise.all([getMenuList(), getUserMenuList()]).then(function (
              values
            ) {
              const [allMenus, userMenus] = values
              // 存储所有菜单
              auth.setMenu(formatData(allMenus.data.data))
              // 存储用户菜单
              auth.setUserMenu(userMenus.data.data)
            })
          }
        } else {
          data.message && message.warning(data.message)
        }
      })
      .catch(error => {
        console.log(error)
      })
  const logout = async () => auth.logout().then(user => setUser(null))

  return (
    <AuthContext.Provider children={children} value={{ user, login, logout }} />
  )
}
//自定义hooks
export const useAuth = () => {
  const context = React.useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth必须在AuthProvider中使用')
  }
  return context
}
