/*
 * @Author: your name
 * @Date: 2021-06-17 15:10:58
 * @LastEditTime: 2021-07-06 16:39:40
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /wf-knowledge-manager-static/src/index.js
 */
import React from 'react'
import ReactDOM from 'react-dom'
import './index.less'
import reportWebVitals from './reportWebVitals'
import { ConfigProvider, Layout } from 'antd'
import zhCN from 'antd/lib/locale-provider/zh_CN'
// 引入Switch
import Home from './views/Home'
import Subject from './views/Subject'
import SubjectList from './views/Subject/subject-list'
import Profile from './views/Profile'
import Notice from './views/Notice'
import Detail from './views/Notice/notice-detail'
import LoginView from './views/Login'
import PageNotFound from './views/PageNotFound'
import Head from './components/Head'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import { AppProviders } from './context'
import PrivateRoute from './PrivateRoute'
const { Content } = Layout
ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={zhCN}>
      <AppProviders>
        <BrowserRouter basename="/web/">
          <Layout>
            <Head />
            <Content className="main-wrap">
              <Switch>
                <Route exact path="/" component={Home}></Route>
                <PrivateRoute
                  path="/subject"
                  component={Subject}
                ></PrivateRoute>
                <PrivateRoute
                  path="/subject-list"
                  component={SubjectList}
                ></PrivateRoute>
                <PrivateRoute
                  path="/profile"
                  component={Profile}
                ></PrivateRoute>

                <Route path="/notice" component={Notice}></Route>
                <Route path="/detail/:id" component={Detail}></Route>
                <Route path="/login" component={LoginView}></Route>
                {/* 404页面没有path 当有path的路由都不匹配时那必然匹配404*/}
                <Route component={PageNotFound}></Route>
              </Switch>
            </Content>
          </Layout>
        </BrowserRouter>
      </AppProviders>
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
