import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { getToken } from './auth-provider'
// 保留路由组件所需的参数component、以及其他参数例如path等，同时传一个登录状态isLogin
const PrivateRoute = ({ component: Comp, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        getToken() ? (
          <Comp {...props}></Comp>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { redirect: props.location.pathname },
            }}
          ></Redirect>
        )
      }
    ></Route>
  )
}
export default PrivateRoute
