import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Divider } from 'antd'
import moment from 'moment'
import './index.less'
import Banner from '../../components/Banner'
import { noticeListRequest } from '../../service/api'
import Foot from '../../components/Foot'
const Home = () => {
  const [notices, setNotices] = useState([])

  useEffect(() => {
    getNoticeList()
  }, [])

  //1.获取新闻列表
  const getNoticeList = async () => {
    const res = await noticeListRequest({
      page: 1,
      rows: 4,
    })
    const resData = res.data
    if (resData.code === 200) {
      setNotices(resData.data.list)
      //setTotal()
    }
  }
  return (
    <div direction="vertical" className="home">
      <Banner />
      <div className="home-notice_head-wrap">
        <h2 className="notice-head">
          <span></span>通知公告
        </h2>
        <div className="notice-more">
          <NavLink to="/notice" replace>
            More
          </NavLink>
        </div>
      </div>
      <Divider dashed style={{ width: '100%' }} />
      <div className="home-notice_content-wrap">
        {notices &&
          notices.map(notice => {
            return (
              <Link to={`/detail/${notice.id}`} key={notice.id}>
                <div className="notice-item">
                  <div className="notice-time">
                    <div className="notice-day">
                      {moment(notice.updateTime).format('DD')}
                    </div>
                    <div className="notice-month">
                      {moment(notice.updateTime).format('YYYY-MM')}
                    </div>
                  </div>
                  <div className="notice-content">
                    <div className="notice-title">{notice.title}</div>
                    <div className="notice-summary">{notice.summary}</div>
                  </div>
                  <Divider className="divier" dashed="true" />
                </div>
              </Link>
            )
          })}
      </div>
      <Foot />
    </div>
  )
}
export default Home
