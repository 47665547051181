//StepTwo.js
import React, { useEffect, useState, useCallback } from 'react'
import { Card, Form, Input, DatePicker, Upload, Button, message } from 'antd'
import { uploadRequest } from '../../service/api'
import { InboxOutlined } from '@ant-design/icons'
import {
  stepRequest,
  saveTopicFieldsRequest,
  downTopicFieldsRequest,
} from '../../service/api'
import './steps/index.less'
import { objToArr, download } from './common'
const { RangePicker } = DatePicker
const { TextArea } = Input
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
}
const { Dragger } = Upload
const FormItem = Form.Item
// 函数组件
const StepTemp = props => {
  const { setCanNext, topicId, groupId } = props

  //console.log(groupId)
  const [form] = Form.useForm()
  const [fields, setFields] = useState([])
  const getStepList = useCallback(async () => {
    const res = await stepRequest({
      groupId,
    })
    const resData = res.data
    if (resData.code === 200) {
      setFields(resData.data)
    } else {
      message.error(resData.message)
    }
  }, [groupId])

  useEffect(() => {
    if (!groupId) return

    getStepList()
  }, [groupId, getStepList])

  //1.获取课题-组-字段 请求列表

  //文件上传属性配置
  const fileprops = {
    name: 'file',
    listType: 'text',
    showUploadList: true,
    customRequest: async info => {
      // 手动上传
      const formData = new FormData()
      formData.append('file', info.file)
      formData.append('type', 'KNOW')

      const resp = await uploadRequest(formData)
      const res = resp.data
      if (res.code === 200) {
        message.success(`${info.file.name} 文件上传成功`)
        info.onSuccess(resp, info.file)
      } else {
        message.error(`${info.file.name} 文件上传失败.`)
      }
    },
    // onChange(info) {
    //   const { status } = info.file
    //   if (status === 'done') {
    //     message.success(`${info.file.name} 文件上传成功`)
    //   } else if (status === 'error') {
    //     message.error(`${info.file.name} 文件上传失败.`)
    //   }
    // },
  }

  //文件下载
  const handleDownload = async resouceId => {
    const res = await downTopicFieldsRequest(resouceId)
    try {
      download(res)
    } catch (error) {
      console.log(error)
    }
  }
  /**
   * 根据后台返回的 data 中 type 类型生成不同的组件
   * @param item  json
   * @param Component
   */
  const switchItem = (item, form) => {
    const type = item.inputType
    switch (type) {
      case 1:
        return (
          <h3 key={item.id} className="subject-step_title2">
            {item.name}
          </h3>
        )
      case 2: // 文本框
        return <Input type="text" placeholder={`请输入${item.name}`} />
      case 3: //单日期
        return <DatePicker format="YYYY-MM-DD" />
      case 4: //双日期
        return <RangePicker format="YYYY-MM-DD" />
      case 6: //附件 上传
        return (
          <Dragger {...fileprops} style={{ marginTop: 20 }}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">单击或拖动文件到此区域以内上传</p>
          </Dragger>
        )

      case 7: //模板 下载
        return (
          <div
            key={item.id}
            onClick={() => handleDownload(item.inputValue)}
            style={{ cursor: 'pointer', color: '#A8081A' }}
          >
            {item.name}
          </div>
        )
      case 8: //费用
        return <Input type="text" suffix="万元" />

      case 9: //文本域
        return (
          <TextArea
            showCount
            maxLength={500}
            rows={8}
            placeholder="不超过500字"
            autoSize={{ minRows: 8, maxRows: 16 }}
          />
        )
      default: // TODO type=10 富文本未完成 返回undefined
        return <div>完成</div>
    }
  }
  const onFinish = async values => {
    const res = await saveTopicFieldsRequest(topicId, objToArr(values))
    const resData = res.data
    if (resData.code === 200) {
      message.success('保存成功')
      setCanNext(false)
    } else {
      message.error(resData.message)
    }
  }
  const normFile = e => {
    if (Array.isArray(e)) {
      return e
    }

    return e && e.fileList
  }
  return (
    <div>
      <Form
        form={form}
        onFinish={onFinish}
        labelAlign="right"
        size="large"
        {...layout}
      >
        <Card bordered={true} className="subject-step-wrap">
          {fields &&
            fields.map((item, index) => {
              const type = item.inputType
              switch (type) {
                case 1: // 小标题
                  return switchItem(item)
                case 6: // 拖住 附件上传
                  return (
                    <FormItem
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                      label={item.name}
                      name={item.id}
                      key={item.id}
                      rules={[
                        {
                          required: item.isMust === 1 ? true : false,
                          message: `请输入${item.name}`,
                        },
                      ]}
                    >
                      {switchItem(item, form)}
                    </FormItem>
                  )
                case 7: // 模版下载
                  return (
                    <FormItem key={item.id} label="下载">
                      {switchItem(item)}
                    </FormItem>
                  )
                default:
                  return (
                    <FormItem
                      key={item.id}
                      label={item.name}
                      name={item.id}
                      rules={[
                        {
                          required: item.isMust === 1 ? true : false,
                          message: `请输入${item.name}`,
                        },
                      ]}
                    >
                      {switchItem(item, form)}
                    </FormItem>
                  )
              }
            })}
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              保存
            </Button>
          </Form.Item>
        </Card>
      </Form>
    </div>
  )
}

export default StepTemp
