import React, { useEffect, useState } from 'react'

import { Carousel, Spin } from 'antd'
import { bannerListRequest } from '../../service/api'
import './tab.less'

const Banner = () => {
  const [banners, setBanners] = useState([])
  const [Loading, setLoading] = useState(false)
  useEffect(() => {
    getBannerList()
  }, [])

  //1.获取新闻列表
  const getBannerList = async () => {
    setLoading(true)
    const res = await bannerListRequest({
      page: 1,
      rows: 8,
    })
    const resData = res.data
    if (resData.code === 200) {
      setLoading(false)
      // const list = [...resData.data]
      // const newARR = list.map(item => ({
      //   ...item,
      //   picUrl: `${process.env.REACT_APP_API_URL}/v1/resources/${item.resourceId}/img`,
      // }))
      setBanners(resData.data)
    }
  }
  return (
    banners.length && (
      <Carousel autoplay className="banner-wrap">
        {Loading ? (
          <Spin tip="正在加载中..."></Spin>
        ) : (
          banners &&
          banners.length > 0 &&
          banners.map(item => (
            <a href={item.url} key={item.id} target="_blank" rel="noreferrer">
              <img src={item.resourceUrl} alt={item.id} />
            </a>
          ))
        )}
      </Carousel>
    )
  )
}

export default Banner
