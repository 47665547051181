/*
 * @Author: your name
 * @Date: 2021-06-12 15:56:13
 * @LastEditTime: 2021-07-06 11:09:05
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /wf-knowledge-manager-static/src/service/api.js
 */
import { http } from './request'
import qs from 'qs'

// 登出
export const logoutRequest = data => {
  return http({
    url: '/v1/auth/ou',
    method: 'POST',
    data: qs.stringify(data),
  })
}
//获取新闻列表
export const noticeListRequest = data => {
  return http({
    url: '/v1/notices/show',
    method: 'GET',
    params: data,
  })
}
// 新闻详情
export const detailNoticeRequest = Id => {
  return http({
    url: `/v1/notices/${Id}`,
    method: 'GET',
  })
}
//获取banner列表
export const bannerListRequest = data => {
  return http({
    url: '/v1/banners',
    method: 'GET',
    params: data,
  })
}
// setp1.课题详情
export const detailSubjectsRequest = topicId => {
  return http({
    url: `/v1/topics/${topicId}`,
    method: 'GET',
  })
}
// 获取step2

export const stepRequest = params => {
  return http({
    url: '/v1/topic-group-fields',
    method: 'GET',
    params,
  })
}

// 1.获取所有课题列表 topicid
export const getTopicGroupsRequest = params => {
  return http({
    url: '/v1/topic-groups',
    method: 'GET',
    params,
  })
}
// 2、课题-申报-字段组-保存
export const saveTopicFieldsRequest = (topicId, data) => {
  return http({
    url: `/v1/topic-group-fields/${topicId}/write`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  })
}
// 2、课题-申报-字段组-下载模板
export const downTopicFieldsRequest = resourceId => {
  return http({
    url: `/v1/resources/${resourceId}/down`,
    method: 'GET',
    responseType: 'blob',
  })
}
// 4、课题-申报-字段组-完成
export const finishedTopicFieldsRequest = (topicId, data) => {
  //todo
  return http({
    url: `/v1/topic-group-fields/${topicId}/write`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  })
}
// 课题完成
export const subjectFinishRequest = (topicId, data) => {
  return http({
    url: `/v1/topics/${topicId}/apply`,
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(data),
  })
}

// 获取所有菜单
export const getAllMenuListRequest = () => {
  return http({
    url: '/v1/menus',
    method: 'GET',
  })
}
// 获取用户菜单
export const getUserMenuListRequest = () => {
  return http({
    url: '/v1/users/menus',
    method: 'GET',
  })
}
export const uploadRequest = data => {
  return http({
    url: '/v1/resources',
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data;boundary = ' + new Date().getTime(),
    },
    data: data,
  })
}
export const subjectsRequest = params => {
  return http({
    url: `/v1/topics`,
    method: 'GET',
    params,
  })
}

// 个人中心
export const deleteDynamicRequest = data => {
  return http({
    url: `/v1/users/remove-apply`,
    method: 'DELETE',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })
}
export const modifyDynamicRequest = data => {
  return http({
    url: `/v1/users/apply-revoke`,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })
}
//  获取院校详细信息
export const schoolDeclareRequest = (topicId, applyId) => {
  return http({
    url: `/v1/topics/${topicId}/apply-info/${applyId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

//获取申报记录信息
export const applyInfoRequest = data => {
  return http({
    url: '/v1/users/apply-history',
    method: 'GET',
    params: data,
  })
}
//获取个人信息
export const userInfoRequest = data => {
  return http({
    url: '/v1/users/info',
    method: 'GET',
    params: data,
  })
}

//todo修改昵称
export const updateUserRequest = data => {
  return http({
    url: `/v1/users/nick-name`,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })
}
