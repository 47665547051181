import React from 'react'
import './index.less'
const PageNotFound = () => {
  return (
    <div>
      <div className="wf-noPage">
        <h2>404</h2>
        <p>对不起，您请求的页面不存在、或已被删除、或暂时不可用</p>

        <p>
          {/* <a style="cursor:pointer" οnclick="history.back()" st>
            返回上一页面
          </a> */}
        </p>

        <p>
          >><a href="/">返回网站首页</a>
        </p>
      </div>
    </div>
  )
}

export default PageNotFound
