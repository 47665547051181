import axios from 'axios'
import { message } from 'antd'
import {
  getToken,
  setToken,
  getUUID,
  setMenu,
  setUserMenu,
} from '../auth-provider'
import { base64User, treeDone } from '../utils'
import qs from 'qs'
const baseURL = process.env.REACT_APP_API_URL

const formatData = data => {
  // 格式化子组件为空的情况
  const newData = JSON.parse(JSON.stringify(data))
  return newData && treeDone(newData)
}
export const http = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: getToken(),
  },
})

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, other) => {
  switch (status) {
    // 202: 未登录
    case 202:
      // 未登录则跳转登录页面，并携带当前页面的路径
      // 在登录成功后返回当前页面，这一步需要在登录页操作。
      // router.replace({
      //   path: '/login',
      //   query: { redirect: router.currentRoute.fullPath },
      // })
      console.log('202')
      break
    // 203 token过期
    // 登录过期不对用户进行提示
    // 清除本地token和清空vuex中token对象
    case 404:
      message.error('网络请求不存在')
      break
    default:
      message.error(other)
  }
}
const getAllMenuListRequest = token => {
  return axios({
    url: `${baseURL}/v1/menus`,
    method: 'GET',

    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: token,
    },
  })
}
const getUserMenuListRequest = token => {
  return axios({
    url: `${baseURL}/v1/users/menus`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: token,
    },
  })
}
// 请求拦截
http.interceptors.request.use(
  config => {
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    // 过期时间小于当前时间重新请求token接口
    //console.log(config)

    config.headers['Authorization'] = getToken()
    const user = getToken() && base64User(getToken()) //解析出用户信息
    const exp = user && user.exp
    if (exp && exp < Date.parse(new Date()) / 1000) {
      console.log('过期了，重新请求token')
      let promisefresh = new Promise(function (resolve, reject) {
        //刷新token
        axios({
          url: `${baseURL}/v1/auth/token`,
          method: 'POST',
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
            Authorization: getToken(),
          },
          data: qs.stringify({
            ui: getUUID(),
          }),
        }).then(response => {
          const newToken = response.data.data
          setToken(newToken)
          config.headers.Authorization = newToken

          axios
            .all([
              getAllMenuListRequest(newToken),
              getUserMenuListRequest(newToken),
            ])
            .then(
              axios.spread(function (menus, userMenus) {
                // 存储所有菜单
                setMenu(formatData(menus.data.data))
                // 存储用户菜单
                setUserMenu(userMenus.data.data)
              })
            )

          resolve(config)
        })
      })
      return promisefresh
    } else {
      return config
    }
  },
  error => {
    return Promise.error(error)
  }
)
//响应拦截
http.interceptors.response.use(
  // 请求成功
  res => {
    if (res.status === 200) {
      // if (res.data.code === 202) {
      //   // 无登录
      //   //todo
      //   // router 跳转到登录页
      // } else {
      //   message.error(res.data.message)
      // }
      return Promise.resolve(res)
    } else {
      return Promise.reject(res)
    }
  },
  // 请求失败
  error => {
    const { response } = error
    console.log(error) // 程序出错render
    if (response) {
      // 请求已发出，但是不在200的范围
      errorHandle(response.status, response.data.msg)
      return Promise.reject(response)
    } else {
      message.error('网络环境不稳定')
      // 处理断网的情况
      // eg:请求超时或断网时，更新state的network状态
      // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
      // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
      // store.commit('changeNetwork', false);
    }
  }
)
