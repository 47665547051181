import React, { useEffect, useState } from 'react'
import { Layout, Divider, Pagination } from 'antd'
import Foot from '../../components/Foot'
import { Link } from 'react-router-dom'
import './index.less'
import { subjectsRequest } from '../../service/api'
import { PAGE_SIZE } from '../../utils/Constants'

const { Content } = Layout

const SubjectList = () => {
  const [subjects, setSubjects] = useState([])
  const [currentPageNum, setCurrentPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const [total, setTotal] = useState(0)

  useEffect(() => {
    getSubjectsList(currentPageNum, pageSize)
  }, [currentPageNum, pageSize])
  // 翻页
  const currentPageChange = current => {
    setCurrentPageNum(current)
  }
  // 条数改变
  const pageSizeChange = (currentPageNum, pageSize) => {
    //设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
    setPageSize(pageSize)
  }

  /*
  获取新闻列表
   */
  const getSubjectsList = async (currentPageNum, pageSize) => {
    const res = await subjectsRequest({
      page: currentPageNum || 1,
      rows: pageSize,
    })
    const resData = res.data
    if (resData.code === 200) {
      setSubjects(resData.data.list)
      setTotal(resData.data.totalRecords)
    } else {
    }
  }

  return (
    <div className="notice">
      <Layout>
        <Content>
          <div className="notice-head-wrap">
            <h2 className="notice-head">
              <span></span>课题列表
            </h2>
          </div>
        </Content>
        <Content className="notice-list-wrap">
          <Divider dashed />
          {subjects &&
            subjects.map(subject => {
              return (
                <Link
                  to={{ pathname: '/subject', state: { topicId: subject.id } }}
                  key={subject.id}
                >
                  <div className="notice-list-item">
                    <div className="new-title">{subject.name}</div>
                    <div className="new-time">{subject.endTime}</div>
                  </div>
                  <Divider className="divier" />
                </Link>
              )
            })}
          <Pagination
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '25px',
            }}
            total={total}
            showSizeChanger={true}
            showTotal={detailTotal => `总共 ${total} 条记录`}
            pageSize={pageSize}
            onChange={currentPageNum => currentPageChange(currentPageNum)} //翻页
            onShowSizeChange={(currentPageNum, pageSize) =>
              pageSizeChange(currentPageNum, pageSize)
            }
          />
        </Content>
      </Layout>
      <Foot />
    </div>
  )
}
export default SubjectList
