import React, { useState } from 'react'
import { Layout, Form, Input, Button, Col, Row, message } from 'antd'
import { getUUID, setUUID, getToken } from '../../auth-provider'
import { Redirect } from 'react-router-dom'
import Foot from '../../components/Foot'
import './index.less'
import { smsRequest } from '../../service/captcha'
import { useAuth } from '../../context/auth-context'
const { Content } = Layout

// 从路由参数拿到重定向地址，从redux获取登录状态和登录方法
const LoginView = props => {
  const { login } = useAuth()
  const [isDisabled, setIsDisabled] = useState('')
  const [form] = Form.useForm()
  const redirect = props.location.state?.redirect || '/'

  if (getToken()) {
    return <Redirect to={redirect}></Redirect>
  }

  const onSendPhoneCaptcha = async e => {
    const { contactPhone } = await form.validateFields(['contactPhone'])
    if (contactPhone) {
      smsRequest({
        mobile: contactPhone,
        type: 'SMS_CODE',
      }).then(resp => {
        // console.log(resp)
        // console.log(resp.status)
        if (resp.status === 200) {
          let timeNum = 60
          /*当点击获取验证码后设置60秒计时不可点击*/
          const timeTicket = setInterval(function () {
            if (timeNum > 1) {
              timeNum--
              /*设置按钮不可点击*/
              setIsDisabled(true)
              e.target.innerText = timeNum + '秒后可点击'
            } else if (timeNum === 1) {
              e.target.innerText = '获取验证码'
              setIsDisabled(false)
              timeNum = 60
              clearInterval(timeTicket)
            }
          }, 1000)
          if (resp.data.code === 200) {
            if (!getUUID()) {
              setUUID(new Date().getTime())
            }

            message.success('验证码发送成功')
          } else {
            message.error(resp.data.message)
          }
        } else {
          message.error(resp.data.message)
        }
      })
    }
  }
  const onFinish = values => {
    login(values)
    // .then(reponse => {
    //   console.log(reponse)
    //   const data = reponse.data
    //   if (data.code === 200) {
    //     const token = data.data
    //     setToken(token)
    //     // console.log('finsi')
    //     // console.log(getToken())
    //     getToken() && props.history.push(redirect)
    //   } else {
    //     data.message && message.warning(data.message)
    //   }
    // })
    // .catch(error => {
    //   console.log(error)
    // })
  }
  return (
    <Layout>
      <Content>
        <div className="main-wrap">
          {/* <div className="login-header">
            <NavLink to="/" replace>
              课题申报网
            </NavLink>
          </div> */}
          <div className="login-content-warp">
            <h2 className="login-title">登录</h2>
            <div className="login-wrap">
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                  remember: true,
                }}
                form={form}
                onFinish={onFinish}
              >
                <Form.Item
                  name="contactPhone"
                  rules={[
                    {
                      // pattern: /^1[3|4|5|7|8][0-9]\d{8}$/,
                      required: true,
                      message: '请输入正确的手机号!',
                    },
                  ]}
                >
                  <Input addonBefore="+86" placeholder="11位手机号" />
                </Form.Item>
                <Form.Item>
                  <Row gutter={12}>
                    <Col span={17}>
                      <Form.Item
                        name="captcha"
                        rules={[
                          {
                            required: true,
                            message: '请输入联系人手机验证码!',
                          },
                        ]}
                      >
                        <Input placeholder="输入验证码" autoComplete="off" />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Button
                        onClick={onSendPhoneCaptcha}
                        disabled={isDisabled}
                      >
                        获取验证码
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
                <p style={{ textAlign: 'center' }}>
                  无需注册，使用手机号一键登录
                </p>
                <Form.Item>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    登录
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </Content>
      <Foot />
    </Layout>
  )
}
export default LoginView
